import React from 'react'

import * as styles from './styles.module.scss'

const FlaredButton = ({ children }) => (
  <button className={`${styles.button} ${styles.buttonFlared}`}>
    {children}
  </button>
)

const MainButton = ({ children, className }) => (
  <button className={`${styles.button} ${styles.buttonMain} ${className}`}>
    {children}
  </button>
)

const LinkButton = ({ children }) => (
  <button className={`${styles.button} ${styles.buttonLink}`}>
    <div />
    <span>{children}</span>
  </button>
)

const ArrowButton = ({ ...props }) => (
  <button {...props} className={`${styles.button} ${styles.buttonArrow}`}>
    <div />
  </button>
)

const TransparentButton = ({ children }) => (
  <button className={`${styles.button} ${styles.buttonTransparent}`}>
    {children}
  </button>
)

const GrayButton = ({ children, ...props }) => (
  <button {...props} className={`${styles.button} ${styles.buttonGray}`}>
    {children}
  </button>
)

export {
  FlaredButton,
  MainButton,
  LinkButton,
  ArrowButton,
  TransparentButton,
  GrayButton,
}
