import React, { useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'

import Header from './sharedComponents/Header/Header'
import Footer from './sharedComponents/Footer/Footer'
import { GrayButton } from './sharedComponents/buttons/Buttons'
import * as styles from './layout.module.scss'

import '../styles/default.css'

const Layout = ({ children }) => {
  const [isAlert, setIsAlert] = useState(true)
  const [isIframe, setIsIframe] = useState(false)

    useLayoutEffect(() => {
        if ( typeof window !== 'undefined' && window !== window.top ){
            setIsIframe(true)
        } else setIsIframe(false)
    }, [])


  const Alert = () => (
    <div className={styles.alert}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle cx="20" cy="20" r="20" fill="white" fillOpacity="0.1" />
        <path
          d="M31.5214 19.0758C29.9777 18.5214 29.0282 16.9504 29.1475 15.7099C29.1475 15.5059 29.0253 15.3164 28.8708 15.1825C28.7163 15.0493 28.4766 14.9917 28.2761 15.0239C25.9236 15.369 23.7695 13.648 23.5561 11.2288C23.5369 11.0105 23.4174 10.8141 23.232 10.6967C23.0473 10.5799 22.8173 10.5545 22.6134 10.6307C20.7065 11.329 18.9048 10.0664 18.1859 8.4212C18.0513 8.11357 17.7176 7.94397 17.3887 8.01677C11.9759 9.22459 8 14.4526 8 20C8 26.5911 13.4089 32 20 32C26.5911 32 32 26.5911 32 20C31.9805 19.5813 31.9775 19.2392 31.5214 19.0758ZM12.9336 20.7561C12.5453 20.7561 12.2305 20.4413 12.2305 20.053C12.2305 19.6646 12.5453 19.3498 12.9336 19.3498C13.3219 19.3498 13.6367 19.6646 13.6367 20.053C13.6367 20.4413 13.3219 20.7561 12.9336 20.7561ZM15.7813 26.3281C14.6181 26.3281 13.6719 25.3819 13.6719 24.2188C13.6719 23.0556 14.6181 22.1094 15.7813 22.1094C16.9444 22.1094 17.8906 23.0556 17.8906 24.2188C17.8906 25.3819 16.9445 26.3281 15.7813 26.3281ZM16.4844 17.8907C15.7092 17.8907 15.0781 17.2596 15.0781 16.4844C15.0781 15.7092 15.7092 15.0782 16.4844 15.0782C17.2596 15.0782 17.8906 15.7092 17.8906 16.4844C17.8906 17.2596 17.2596 17.8907 16.4844 17.8907ZM22.1094 27.7344C21.3342 27.7344 20.7031 27.1034 20.7031 26.3281C20.7031 25.5529 21.3342 24.9219 22.1094 24.9219C22.8846 24.9219 23.5156 25.5529 23.5156 26.3281C23.5156 27.1034 22.8846 27.7344 22.1094 27.7344ZM22.8125 22.1094C21.6493 22.1094 20.7031 21.1632 20.7031 20C20.7031 18.8369 21.6493 17.8907 22.8125 17.8907C23.9757 17.8907 24.9219 18.8369 24.9219 20C24.9219 21.1632 23.9757 22.1094 22.8125 22.1094ZM26.9961 23.5686C26.6078 23.5686 26.293 23.2538 26.293 22.8655C26.293 22.477 26.6078 22.1623 26.9961 22.1623C27.3844 22.1623 27.6992 22.477 27.6992 22.8655C27.6992 23.2538 27.3844 23.5686 26.9961 23.5686Z"
          fill="white"
        />
      </svg>
      <div className={styles.alertText}>
        We track sessions with cookies{' '}
        <Link
          to="/terms-and-conditions/cookies"
          className={styles.alertTextLink}
        >
          For what?
        </Link>
      </div>
      <GrayButton onClick={() => handleSetCookies()}>Ok</GrayButton>
    </div>
  )

  const cookiesAlert = () => {
    const checkLocalStorage =
      typeof window !== 'undefined' &&
      localStorage?.getItem('cookiesTracking') === 'true'
    if (isAlert && !checkLocalStorage) {
      return <Alert />
    } else {
      return null
    }
  }

  const handleSetCookies = () => {
    localStorage.setItem('cookiesTracking', 'true')
    setIsAlert(false)
  }

  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
          !isIframe
        ? <>
           <div>
             <Header />
             <div className="container">{cookiesAlert()}</div>
             <main className="main-wrap">{children}</main>
             <Footer />
           </div>
         </>
        : <h1> We do not allow our site "it-justice.com" to be used in IFrame </h1>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
