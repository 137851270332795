import React from 'react'
import { Link } from 'gatsby'

import * as styles from './styles.module.scss'

const NavLink = ({ children, ...props }) => (
  <a {...props} className={`${styles.link} ${styles.linkNav}`}>
    {children}
  </a>
)

const BlueLink = ({ children, ...props }) => (
  <a {...props} className={`${styles.link} ${styles.linkBlue}`}>
    {children}
  </a>
)

const WhiteLink = ({ children, className, ...props }) => (
  <Link
    {...props}
    className={`${styles.link} ${styles.linkWhite} ${className}`}
  >
    {children}
  </Link>
)
const WhiteLinks = ({ children, className, ...props }) => (
  <a {...props} className={`${styles.link} ${styles.linkWhite} ${className}`}>
    {children}
  </a>
)

export { NavLink, BlueLink, WhiteLink, WhiteLinks }
