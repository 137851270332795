import React from 'react'

import { NavLink } from '../Links/Links'
import {
  BehLink,
  ClutchLink,
  GoodLink,
  InLink,
  VkLink,
} from '../socialLinks/socialLinks'
import { Link } from 'gatsby'

import * as styles from './styles.module.scss'
import logo from '../../../assets/images/svg/Logo.svg'

const Footer = () => {
  const today = new Date()
  const year = today.getFullYear()

  const linksArr = [
    {
      title: 'Services',
      id: 1123,
      children: [
        <Link
          to="/services/"
          key="dsfd"
          className={styles.footerBlockLinksLink}
        >
          Dedicated teams
        </Link>,
        <Link
          to="/services/#team-transfer"
          key="dfddadf"
          className={styles.footerBlockLinksLink}
        >
          Team transfer
        </Link>,
        <Link
          to="/services/#full-cycle-dev"
          key="dfddadddf"
          className={styles.footerBlockLinksLink}
        >
          Full cycle development
        </Link>,
        <Link
          to="/services/#full-product-dev"
          key="d34dddf"
          className={styles.footerBlockLinksLink}
        >
          Full product development
        </Link>,
      ],
    },
    {
      title: 'Industries',
      id: 312312,
      children: [
        <Link
          to="/industries/fintech/"
          key="df231f"
          className={styles.footerBlockLinksLink}
        >
          Fintech
        </Link>,
        <Link
          to="/industries/healthcare/"
          key="df690df"
          className={styles.footerBlockLinksLink}
        >
          Healthcare
        </Link>,
        <Link
          to="/industries/e-commerce/"
          key="5364"
          className={styles.footerBlockLinksLink}
        >
          E-commerce
        </Link>,
        <Link
          to="/industries/renewable-resources/"
          key="53rwwerw64"
          className={styles.footerBlockLinksLink}
        >
          Renewable Energy
        </Link>,
      ],
    },
    {
      title: 'More',
      id: 312342312,
      children: [
        <Link
          to="/careers/"
          key="5364kkk"
          className={styles.footerBlockLinksLink}
        >
          Careers
        </Link>,
        <Link
          to="/startup-library/"
          key="kw5rkler"
          className={styles.footerBlockLinksLink}
        >
          Startup Library
        </Link>,
        <Link
          to="/whitepapers/"
          key="sdfsd"
          className={styles.footerBlockLinksLink}
        >
          Whitepapers
        </Link>,
        <Link
          to="/blog/"
          key="dsfsd34329"
          className={styles.footerBlockLinksLink}
        >
          Blog
        </Link>,
      ],
    },
  ]

  const LinksBlock = ({ title, children }) => (
    <div className={styles.footerBlockLinks}>
      <h4>{title}</h4>
      {children}
    </div>
  )

  return (
    <div className={styles.footer}>
      <div className="container">
        <div className={styles.footerTop}>
          <div className={styles.footerLogo}>
            <Link to="/" className={styles.footerLogoImage}>
              <img src={logo} alt="logo" />
            </Link>

            <p>
              Justice — Our clients solve high-level tasks, while we take full
              responsibility for development process.{' '}
            </p>
          </div>
          {linksArr.map((item) => {
            return <LinksBlock {...item} key={item.id} />
          })}
          <div className={styles.footerBlockLinks}>
            <h4>Contacts</h4>
            {/*<NavLink href="/contacts/">Taganrog Petrovskaya street 94</NavLink>*/}
            <NavLink href="tel:+79384171744">+7 (938) 417-17-44</NavLink>
            <NavLink href="mailto:hello@it-justice.com">
              hello@it-justice.com
            </NavLink>
            <div className={styles.footerSocialLinks}>
              <InLink
                target="_blank"
                href="https://ru.linkedin.com/company/justice-team-it"
              />
              <VkLink target="_blank" href="https://vk.com/justice_it" />
              <BehLink
                target="_blank"
                href="https://www.behance.net/justiceit?tracking_source=search_users_recommended%7CJustice%20It"
              />
              <ClutchLink
                target="_blank"
                href="https://clutch.co/profile/justice-it#summary"
              />
              <GoodLink
                target="_blank"
                href="https://www.goodfirms.co/company/justice-it"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.footerBottom}>
        <div className={`${styles.footerBottomContainer} container`}>
          <p>© {year} Justice IT. All rights reserved.</p>

          <Link
            to="/terms-and-conditions/cookies/"
            className={styles.footerBlockLinksLink}
          >
            Terms & conditions
          </Link>
          <Link
            to="/terms-and-conditions/privacy-policy/"
            className={styles.footerBlockLinksLink}
          >
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  )
}

export default Footer
