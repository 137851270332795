import React, { useEffect, useState } from 'react'

import { NavLink } from '../Links/Links'
import Burger from '../Burger/Burger'
import { InLink, VkLink } from '../socialLinks/socialLinks'
import { Link } from 'gatsby'

import * as styles from './styles.module.scss'
import logo from '../../../assets/images/svg/Logo.svg'

const Header = () => {
  const [open, setOpen] = useState(false)

  const navLinksArr = [
    {
      id: 1,
      text: 'Services',
      href: '/#services',
    },
    {
      id: 2,
      text: 'Industries',
      href: '/#industries',
    },
    {
      id: 3,
      text: 'Facts',
      href: '/#facts',
    },
    {
      id: 4,
      text: 'Case Studies',
      href: '/#case',
    },
    {
      id: 5,
      text: 'Our projects',
      href: '/#projects',
    },
    {
      id: 6,
      text: 'Blog',
      href: '/blog',
    },
  ]

  useEffect(() => {
    const html = document.querySelector('html')
    const body = document.querySelector('body')

    let styleHidden = {
      overflow: 'hidden',
      position: 'fixed',
      height: '100%',
      width: '100%',
    }
    if (open) {
      html.style.overflow = 'hidden'
      for (let key in styleHidden) {
        body.style[key] = styleHidden[key]
      }
    } else {
      html.style.overflow = 'auto'
      body.style.overflow = 'hidden'
      body.style.position = 'relative'
    }
  }, [open])

  return (
    <div className={styles.headerWrapper}>
      <div className="container">
        <div className={styles.header}>
          <Link to="/" className={styles.headerLogo}>
            <img src={logo} alt="logo" />
          </Link>

          <Burger open={open} setOpen={setOpen} />

          <div
            className={
              open ? `${styles.headerNav} ${styles.open}` : styles.headerNav
            }
          >
            {navLinksArr.map(({ text, id, href }) => {
              return (
                <Link
                  to={href}
                  key={id}
                  onClick={() => setOpen(false)}
                  className={styles.headerNavLink}
                >
                  {text}
                </Link>
              )
            })}

            <Link
              to="/contacts"
              className={styles.headerNavButton}
              onClick={() => setOpen(false)}
            >
              Contact
            </Link>

            <div className={styles.headerLinks}>
              <NavLink href="mailto:hello@it-justice.com">
                hello@it-justice.com
              </NavLink>
              <div className={styles.headerSocialLinks}>
                <InLink
                  target="_blank"
                  href="https://ru.linkedin.com/company/justice-team-it"
                />
                <VkLink target="_blank" href="https://vk.com/justice_it" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Header
