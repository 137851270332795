import React from 'react'

import * as styles from './styles.module.scss'

const InLink = ({ ...props }) => (
  <a
    {...props}
    className={styles.icon}
    aria-label="linkedin link"
    rel="noreferrer nofollow"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 21V3V1.7309C0 0.774902 0.774902 0 1.7309 0H3H21H22.2691C23.2251 0 24 0.774902 24 1.7309V3V21V22.2691C24 23.2251 23.2251 24 22.2691 24H21H3H1.7309C0.774902 24 0 23.2251 0 22.2691V21ZM5.59039 18.1406H8.51294V9.34808H5.59039V18.1406ZM7.03271 8.14746H7.05176C8.07092 8.14746 8.7052 7.47235 8.7052 6.6286C8.68616 5.76581 8.07092 5.10938 7.07117 5.10938C6.07141 5.10938 5.41772 5.76581 5.41772 6.6286C5.41772 7.47235 6.052 8.14746 7.03271 8.14746ZM16.1288 18.1406H19.051V13.099C19.051 10.3984 17.6093 9.14172 15.6865 9.14172C14.1359 9.14172 13.441 9.9939 13.0527 10.593V9.34808H10.1303C10.1686 10.173 10.1303 18.1406 10.1303 18.1406H13.0527V13.2305C13.0527 12.9677 13.0715 12.7051 13.1488 12.5173C13.3601 11.9923 13.8409 11.4485 14.6483 11.4485C15.7057 11.4485 16.1288 12.2547 16.1288 13.4368V18.1406Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  </a>
)

const VkLink = ({ ...props }) => (
  <a
    {...props}
    className={styles.icon}
    aria-label="Vk link"
    rel="noreferrer nofollow"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3ZM19.5642 16.0999C19.5697 16.1106 19.5738 16.1186 19.5768 16.124C20.0879 17.0319 19.0097 17.1031 19.0097 17.1031L16.9489 17.1341C16.9489 17.1341 16.5063 17.2281 15.9238 16.7995C15.6192 16.5753 15.3214 16.2096 15.0376 15.8612C14.6036 15.3282 14.2024 14.8355 13.8601 14.9517C13.2853 15.1469 13.3036 16.4721 13.3036 16.4721C13.3036 16.4721 13.3084 16.7551 13.1773 16.9059C13.0355 17.0701 12.7568 17.1031 12.7568 17.1031H11.8349C11.8349 17.1031 9.80012 17.2343 8.00738 15.2357C6.0536 13.0553 4.3274 8.73071 4.3274 8.73071C4.3274 8.73071 4.22807 8.44771 4.33608 8.31034C4.45758 8.15644 4.78836 8.14611 4.78836 8.14611L6.99288 8.13165C6.99288 8.13165 7.20118 8.16883 7.34969 8.28555C7.47216 8.38161 7.54063 8.56133 7.54063 8.56133C7.54063 8.56133 7.89744 9.52706 8.36901 10.3998C9.28998 12.1051 9.71911 12.478 10.0325 12.2951C10.4887 12.0287 10.3517 9.88546 10.3517 9.88546C10.3517 9.88546 10.3604 9.10668 10.1222 8.76067C9.93802 8.49212 9.59085 8.41362 9.43752 8.39193C9.31312 8.37438 9.5166 8.06555 9.78083 7.92714C10.1772 7.71954 10.8783 7.70714 11.7057 7.71644C12.3508 7.72367 12.536 7.76705 12.7887 7.83212C13.3739 7.98326 13.3557 8.46828 13.3164 9.51507C13.3047 9.82748 13.2911 10.1899 13.2911 10.6085C13.2911 10.7029 13.2885 10.8036 13.2858 10.9072C13.2721 11.4428 13.2564 12.0562 13.5852 12.2838C13.754 12.4005 14.1677 12.3013 15.2005 10.4226C15.6904 9.53222 16.0578 8.48489 16.0578 8.48489C16.0578 8.48489 16.1379 8.29898 16.2623 8.21841C16.3905 8.13682 16.5622 8.1616 16.5622 8.1616L18.8805 8.14714C18.8805 8.14714 19.5777 8.05832 19.6906 8.39503C19.8092 8.74827 19.4302 9.57457 18.4822 10.9276C17.5787 12.2173 17.1412 12.688 17.1859 13.1074C17.2182 13.4104 17.5023 13.6866 18.0444 14.2256C19.1903 15.3646 19.4935 15.9607 19.5642 16.0999Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  </a>
)

const InstLink = ({ ...props }) => (
  <a
    {...props}
    className={styles.icon}
    aria-label="instagram link"
    rel="noreferrer nofollow"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.116 5.50146H7.88438C6.57064 5.50146 5.50159 6.57045 5.50159 7.88426V16.1159C5.50159 17.4299 6.57064 18.4989 7.88438 18.4989H16.116C17.43 18.4989 18.499 17.4299 18.499 16.1159V7.88426C18.499 6.57052 17.43 5.50146 16.116 5.50146ZM12 16.2788C9.64099 16.2788 7.72145 14.3592 7.72145 11.9999C7.72145 9.64087 9.64099 7.72133 12 7.72133C14.3594 7.72133 16.2789 9.64087 16.2789 11.9999C16.2789 14.3592 14.3594 16.2788 12 16.2788ZM16.4166 8.6061C15.858 8.6061 15.4039 8.15195 15.4039 7.59375C15.4039 7.03549 15.858 6.58133 16.4166 6.58133C16.9748 6.58133 17.4289 7.03549 17.4289 7.59375C17.4289 8.15201 16.9748 8.6061 16.4166 8.6061Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.9999 9.52954C10.6381 9.52954 9.52905 10.638 9.52905 11.9999C9.52905 13.3623 10.6381 14.471 11.9999 14.471C13.3623 14.471 14.4705 13.3623 14.4705 11.9999C14.4705 10.638 13.3623 9.52954 11.9999 9.52954Z"
        fill="white"
        fillOpacity="0.7"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3ZM20.3066 16.1158C20.3066 18.427 18.427 20.3066 16.1159 20.3066H7.8842C5.57328 20.3066 3.69336 18.427 3.69336 16.1158V7.8842C3.69336 5.57328 5.57328 3.69336 7.8842 3.69336H16.1159C18.427 3.69336 20.3066 5.57328 20.3066 7.8842V16.1158Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  </a>
)

const BehLink = ({ ...props }) => (
  <a
    {...props}
    className={styles.icon}
    aria-label="behance link"
    rel="noreferrer nofollow"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M9.28762 8.76484H8.94503H6.66113V10.8584H9.11633C9.55408 10.8584 9.93473 10.7442 9.93473 9.7355C9.93473 8.72677 9.28762 8.76484 9.28762 8.76484Z"
          fill="#BABABB"
        />
        <path
          d="M9.28762 12.3809H6.66113V15.0454H8.96407C9.00213 15.0454 9.05923 15.0454 9.13536 15.0454C9.51601 15.0454 10.2392 14.8932 10.2392 13.7512C10.2583 12.3999 9.28762 12.3809 9.28762 12.3809Z"
          fill="#BABABB"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0 3C0 1.34315 1.34315 0 3 0H20.981C22.6378 0 23.981 1.34315 23.981 3V20.981C23.981 22.6378 22.6378 23.981 20.981 23.981H3C1.34315 23.981 0 22.6378 0 20.981V3ZM21.8873 2.14105H2.14105V21.8873H21.8873V2.14105Z"
          fill="#BABABB"
        />
        <path
          d="M17.9667 0.00976562H6.01427C2.70262 0.00976562 0 2.71238 0 6.02404V17.9955C0 21.2881 2.70262 23.9907 6.01427 23.9907H17.9857C21.2974 23.9907 24 21.2881 24 17.9765V6.02404C23.981 2.71238 21.2784 0.00976562 17.9667 0.00976562ZM14.0841 7.62277H18.0809V8.5744H14.0841V7.62277ZM12.2189 13.8845C12.2189 16.8535 9.28787 16.9487 9.28787 16.9487H6.58525H6.50912H4.5678V7.0518H6.49009H6.56622H9.26883C10.7343 7.0518 11.8953 7.88923 11.8953 9.64022C11.8953 11.3912 10.4869 11.4864 10.4869 11.4864C12.3521 11.4673 12.2189 13.8845 12.2189 13.8845ZM19.3941 13.7132H14.5789C14.5789 15.6164 16.2157 15.4642 16.2157 15.4642C17.7573 15.4642 17.7002 14.4745 17.7002 14.4745H19.337C19.337 17.139 16.1586 17.0248 16.1586 17.0248C12.3521 17.0248 12.5995 13.2754 12.5995 13.2754C12.5995 13.2754 12.5995 9.50699 16.1586 9.50699C19.908 9.48796 19.3941 13.7132 19.3941 13.7132Z"
          fill="#BABABB"
        />
        <path
          d="M16.1964 10.877C14.7689 10.877 14.5596 12.3805 14.5596 12.3805H17.6048C17.6048 12.3805 17.6238 10.877 16.1964 10.877Z"
          fill="#BABABB"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </a>
)

const ClutchLink = ({ ...props }) => (
  <a
    {...props}
    className={styles.icon}
    aria-label="clutch link"
    rel="noreferrer nofollow"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 1.34315 1.34315 0 3 0H21C22.6569 0 24 1.34315 24 3V21C24 22.6569 22.6569 24 21 24H3C1.34315 24 0 22.6569 0 21V3ZM12.7173 16.7921C13.9872 16.7921 15.1939 16.3027 16.0824 15.3996L16.4509 15.036L18 16.604L17.6571 16.9554C16.3622 18.2729 14.6098 19 12.7173 19C8.89544 19 6 16.0015 6 12.0121C6 8.01064 8.88259 5 12.7173 5C14.597 5 16.3493 5.71443 17.6443 7.0319L17.9871 7.38335L16.4252 8.92646L16.0701 8.57502C15.1682 7.68401 13.9749 7.19522 12.7173 7.19522C10.1145 7.19522 8.22196 9.215 8.22196 12C8.22196 14.7723 10.1145 16.7921 12.7173 16.7921Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  </a>
)

const GoodLink = ({ ...props }) => (
  <a
    {...props}
    className={styles.icon}
    aria-label="goodfirms link"
    rel="noreferrer nofollow"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 0C1.34315 0 0 1.34315 0 3V21C0 22.6569 1.34315 24 3 24H21C22.6569 24 24 22.6569 24 21V3C24 1.34315 22.6569 0 21 0H3ZM9.94831 19.0027V14.9812V14.951H14.5045C15.0355 14.951 15.1281 14.7091 15.108 14.5881V13.6206H9.94831V10.6876C9.94831 10.0103 10.2702 9.86113 10.4311 9.87121H19.0003V15.4952C19.0003 17.4788 16.8681 18.66 15.8019 19.0027H9.94831ZM5 18.9996V8.62841C5.06035 7.41894 5.86899 5 8.6208 5H18.8496V8.74936H9.67687C9.42543 8.80983 8.92254 9.12429 8.92254 9.89836V18.9996H5Z"
        fill="white"
        fillOpacity="0.7"
      />
    </svg>
  </a>
)

export { InLink, VkLink, InstLink, BehLink, ClutchLink, GoodLink }
