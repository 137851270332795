import React from 'react'

import * as styles from './styles.module.scss'

const Burger = ({ open, setOpen }) => (
  <div
    onClick={() => setOpen(!open)}
    className={open ? `${styles.burger} ${styles.open}` : styles.burger}
  >
    <span />
    <span />
    <span />
  </div>
)

export default Burger
